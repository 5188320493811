<template>
    <div>
        <div class="chartContainer">
            <div class="reportMakeLoading"></div>
            <div class="chart">
                <!-- 층 용도별 분포 -->
                <div id="floorUsageSpreadChart" style="width: 300px; height: 300px;"></div>
            </div>
            <div class="chart">
                <!--에너지원별 계량기 개수-->
                <div id="meteringDeviceCountByEnergySourceChart" style="width: 300px; height: 300px;"></div>
            </div>
            <div class="chart">
                <!--사용용도별 계량기 분포-->
                <div id="meteringDeviceSpreadByEnergyUsagePurposeChart" style="width: 300px; height: 300px;"></div>
            </div>
            <div class="chart">
                <!--연도별-에너지원별 소비량 변화-->
                <div id="consumptionTrendByEnergySourceOverYearsChart" style="width: 300px; height: 300px;"></div>
            </div>
            <div class="chart">
                <!--연도별-용도별 소비량 변화-->
                <div id="consumptionTrendByEnergyUsagPurposeOverYearsChart" style="width: 300px; height: 300px;"></div>
            </div>
            <div class="chart">
                <!--연도별-용도별 백분위 변화-->
                <div id="percentileTrendByEnergyUsagePurposeOverYearsChart" style="width: 300px; height: 300px;"></div>
            </div>
            <div class="chart">
                <!--월별-에너지원별 에너지 사용량-->
                <div id="energyUsageAmountByEnergySourceOverMonthChart" style="width: 300px; height: 300px;"></div>
            </div>
            <div class="chart">
                <!--월별-용도별 에너지 소비량-->
                <div id="energyConsumptionByEnergyUsagePurposeOverMonthChart" style="width: 300px; height: 300px;"></div>
            </div>
            <div class="chart">
                <!--단위 면적별 에너지 사용량-->
                <div id="energyConsumptionPerUnitAreaChart" style="width: 300px; height: 300px;"></div>
            </div>

        </div>
    </div>
</template>

<script>
import * as echarts from '@/assets/js/extLib/echarts_5.5.1/echarts.min.js';
import Vue from 'vue';
import { mapGetters } from "vuex";
import axios from 'axios'; // axios 임포트

export default {
    name: 'BuildingEnergyChartMakeVue',
    data() {
        return {
            reqParams: null, //요청파라미터
            targetChartList: {}, //리포트 생성대상 차트 목록
            floorUsageSpreadChartInstance: null, //층용도분포 차트
            meteringDeviceCountByEnergySourceChartInstance: null, //에너지원별 계량기 갯수 차트
            meteringDeviceSpreadByEnergyUsagePurposeChartInstance: null, //사용용도별 계량기 분포 차트
            consumptionTrendByEnergySourceOverYearsChartInstance: null, //연도별-에너지원별 소비량 변화 차트
            consumptionTrendByEnergyUsagPurposeOverYearsChartInstance: null, //연도별-용도별 소비량 변화 차트
            percentileTrendByEnergyUsagePurposeOverYearsChartInstance: null, //연도별-용도별 백분위 변화 차트
            energyUsageAmountByEnergySourceOverMonthChartInstance: null, //월별-에너지원별 에너지 사용량 차트
            energyConsumptionByEnergyUsagePurposeOverMonthChartInstance: null, //월별-용도별 에너지 소비량 차트
            energyConsumptionPerUnitAreaChartInstance: null, //단위면적별 에너지 사용량 차트
        }
    },
    watch: {
        targetChartList: {
            deep: true, // 중첩된 속성도 감지
            async handler(newValue) {
                const allUploaded = Object.values(newValue).every(value => value === 'uploaded');
                if (allUploaded) {
                    console.debug('모든 차트가 uploaded 상태입니다.');
                    
                    // //만약 bepa리포트도  같이 요청했다면 별도 제공하자
                    // if('Y' === this.reqParams.bepaReport ){ 
                    //     await Promise.all([this.requestPdfCreate(), this.bepaPdfReportDownload(this.reqParams.mgmBldPk)]);
                    // }else{
                        await Promise.all([this.requestPdfCreate()]);  //건물에너지리포트 pdf제공
                    // }
                    window.close();
                } else {
                    console.debug('모든 차트가 uploaded 상태가 아닙니다.');
                }
            },
        },
    },
    computed: {
        ...mapGetters({
            commCodeData: "main/getCommCodeData", //공통코드목록(건물용도, 사용승인일)
        }),
    },
    mounted() {
        //생성요청 파라미터 체크
        this.reqParams = this.$route.query;
        //리포트에 필요한 차트 생성 및 차트이미지를 업로드한다.
        this.createChartReport(); 
        //서버로 모든 차트이미지 업로드가 끝나면
        //watch에서 targetChartList 를 체크함. :서버로 리포트를 pdf 로 생성요청한다.
        //watch에서 targetChartList 를 체크함. :만약 bepa리포트도 같이 요청했다면 별도로 제공한다.
        
    },
    methods: {

        createChartReport() {
            
            if(this.reqParams != null ){

                // this.floorUsageSpreadChartInstance = makeFloorUsageSpreadChart(document.getElementById('floorUsageSpreadChart'));

                //년별에너지소비량 리포트 선택한 경우만 차트 생성
                if (this.reqParams.buldEngyUseYy === 'Y') {
                    //생성대상차트목록(세팅)...비동기라서 생성완료 여부 체크해야함
                    Vue.set(this.targetChartList, 'consumptionTrendByEnergySourceOverYearsChart', 'need');
                    Vue.set(this.targetChartList, 'consumptionTrendByEnergyUsagPurposeOverYearsChart', 'need');
                    Vue.set(this.targetChartList, 'percentileTrendByEnergyUsagePurposeOverYearsChart', 'need');

                    //연도별-에너지원별 소비량 변화 차트
                    this.consumptionTrendByEnergySourceOverYearsChartInstance = this.makeConsumptionTrendByEnergySourceOverYearsChartInstance('consumptionTrendByEnergySourceOverYearsChart');
                    //연도별-용도별 소비량 변화 차트
                    this.consumptionTrendByEnergyUsagPurposeOverYearsChartInstance = this.makeConsumptionTrendByEnergyUsagPurposeOverYearsChartInstance('consumptionTrendByEnergyUsagPurposeOverYearsChart');
                    //연도별-용도별 백분위 변화 차트
                    this.percentileTrendByEnergyUsagePurposeOverYearsChartInstance = this.makePercentileTrendByEnergyUsagePurposeOverYearsChartInstance('percentileTrendByEnergyUsagePurposeOverYearsChart');
                }

                //월별에너지소비량 리포트 선택한 경우만 차트 생성
                if (this.reqParams.buldEngyUseMm === 'Y') {
                    //생성대상차트목록(세팅)...비동기라서 생성완료 여부 체크해야함
                    Vue.set(this.targetChartList, 'energyUsageAmountByEnergySourceOverMonthChart', 'need');
                    Vue.set(this.targetChartList, 'energyConsumptionByEnergyUsagePurposeOverMonthChart', 'need');

                    //월별-에너지원별 사용량 차트
                    this.energyUsageAmountByEnergySourceOverMonthChartInstance = this.makeEnergyUsageAmountByEnergySourceOverMonthChartInstance('energyUsageAmountByEnergySourceOverMonthChart');
                    //월별-용도별 에너지 소비량 차트
                    this.energyConsumptionByEnergyUsagePurposeOverMonthChartInstance = this.makeEnergyConsumptionByEnergyUsagePurposeOverMonthChartInstance('energyConsumptionByEnergyUsagePurposeOverMonthChart');
                }

                //유사건물에너지소비량비교 리포트 선택한 경우에만 차트 생성
                if (this.reqParams.smlrBuldEngyUseCompare === 'Y') {
                    //단위 면적별 에너지 사용량
                    Vue.set(this.targetChartList, 'energyConsumptionPerUnitAreaChart', 'need');
                    this.energyConsumptionPerUnitAreaChartInstance = this.makeEnergyConsumptionPerUnitAreaChartInstance('energyConsumptionPerUnitAreaChart');
                }

                //챠트 없이 건물기본정보만 출력할 경우 
                if (this.reqParams.mgmBldPk != null && this.reqParams.year != null){
                    Vue.set(this.targetChartList, 'buildBasicInfo', 'uploaded');
                }

            }else{
                alert('리포트 생성을 위한 건물정보를 선택해주세요.');
                window.close();
            }

        },

        //년도별-에너지원별 에너지 소비량 차트 생성
        makeConsumptionTrendByEnergySourceOverYearsChartInstance(chartId) {
            const chartDomObj = document.getElementById(chartId);
            const chartInstance = echarts.init(chartDomObj);
            const chartOption = {
                                grid: {
                                    left: '30px',
                                    right: '30px', // 필요 시 우측 마진도 조정
                                    top: '50px', // 타이틀과 x축의 간격 조정
                                    bottom: '50px', // 범례와 x축의 간격 조정
                                },
                                legend: {
                                    bottom: 15, // 하단에 10px 위치
                                    itemWidth: 20, 
                                    itemHeight: 14,
                                    padding: 0, // 레전드와 차트 간의 마진을 최소화
                                },
                                title: {
                                    text: '연도별-에너지원별 소비량 변화',
                                    left: 'center',
                                    top: '10px', // 타이틀을 위로 이동하여 그래프와의 간격 줄이기
                                    textStyle: { fontSize: 16 },
                                },
                                xAxis: [
                                        {
                                            type: 'category',
                                            data: [],
                                        },
                                ],
                                yAxis: [
                                    {
                                        type: 'value',
                                        splitLine: { show: true },
                                    },
                                ],
                                series: [], //데이터는 api조회 해서 채운다.
        };

        this.$store.dispatch('main/FETCH_YEAR_ENGY_DATA', //년도별-에너지원별 에너지 소비량
                                {
                                    mgmBldPk: this.reqParams.mgmBldPk,
                                }).then((resultData) => {
                                                    // xAxis.data를 업데이트
                                                    chartOption.xAxis[0].data = resultData.map((item) => item.useyy);

                                                    const elecData = [];
                                                    const gasData = [];
                                                    const hetgData = [];
                                                    const totalData = [];

                                                    resultData.forEach((item) => {
                                                        // 전기 사용량 계산
                                                        const elec = item.elecbaseqty + item.elecaconqty + item.elechetgqty;
                                                        elecData.push(elec.toFixed(2));

                                                        // 가스 사용량 계산
                                                        const gas = item.gasbaseqty + item.gasaconqty + item.gashetgqty;
                                                        gasData.push(gas.toFixed(2));

                                                        // 열 사용량 계산
                                                        const hetg = item.hetgbaseqty + item.hetgaconqty + item.hetghetgqty;
                                                        hetgData.push(hetg.toFixed(2));

                                                        // 총합 계산
                                                        const total = item.allallqty;
                                                        totalData.push(total.toFixed(2));
                                                    });

                                                    // 전기 시리즈 추가
                                                    chartOption.series.push({
                                                        name: '전기',
                                                        type: 'bar',
                                                        stack: 'advertising',
                                                        data: elecData,
                                                        color: '#E64A45',
                                                    });

                                                    // 가스 시리즈 추가
                                                    chartOption.series.push({
                                                        name: '가스',
                                                        type: 'bar',
                                                        stack: 'advertising',
                                                        data: gasData,
                                                        color: '#E6772E',
                                                    });

                                                    // 열 시리즈 추가
                                                    chartOption.series.push({
                                                        name: '열',
                                                        type: 'bar',
                                                        stack: 'advertising',
                                                        data: hetgData,
                                                        color: '#F2C249',
                                                    });

                                                    // 합계 시리즈 추가
                                                    chartOption.series.push({
                                                        name: '합계',
                                                        type: 'line',
                                                        data: totalData,
                                                        color: '#000000',
                                                    });

                                                chartInstance.setOption(chartOption);
                                                chartInstance.on('finished', () => {
                                                    console.log('에너지 소비량 : 년별-에너지원별 에너지 소비량 변화 차트 생성 완료');
                                                    // this.targetChartList[chartId] = 'created';
                                                    Vue.set(this.targetChartList, chartId, 'created');//이번 페이지는 Vue.set 함수를 써야만 watch가 반응하는 케이스임
                                                    this.uploadChartImage(chartInstance, chartId);
                                                });
                                        }).catch((error) => { console.error('오류내용:', error); });
            return chartInstance;
        },

        //년도별-용도별 에너지 소비량 변화 차트 생성
        makeConsumptionTrendByEnergyUsagPurposeOverYearsChartInstance(chartId) {
            const chartDomObj = document.getElementById(chartId);
            const chartInstance = echarts.init(chartDomObj);
            const chartOption = {
                                grid: {
                                    left: '30px',
                                    right: '30px', // 필요 시 우측 마진도 조정
                                    top: '50px', // 타이틀과 x축의 간격 조정
                                    bottom: '50px', // 범례와 x축의 간격 조정
                                },
                                legend: {
                                    bottom: 15, // 하단에 10px 위치
                                    itemWidth: 20, 
                                    itemHeight: 14,
                                    padding: 0, // 레전드와 차트 간의 마진을 최소화
                                },
                                title: {
                                    text: '연도별-용도별 소비량 변화',
                                    left: 'center',
                                    top: '10px', // 타이틀을 위로 이동하여 그래프와의 간격 줄이기
                                    textStyle: { fontSize: 16 },
                                },
                                xAxis: [
                                        {
                                            type: 'category',
                                            data: [],
                                        },
                                ],
                                yAxis: [
                                    {
                                        type: 'value',
                                        splitLine: { show: true },
                                    },
                                ],
                                series: [], //데이터는 api조회 해서 채운다.
        };

        this.$store.dispatch('main/FETCH_YEAR_ENGY_DATA', //년도별-용도별 에너지 소비량
                                {
                                    mgmBldPk: this.reqParams.mgmBldPk,
                                }).then((resultData) => {
                                                   // xAxis.data를 업데이트
                                                    chartOption.xAxis[0].data = resultData.map((item) => item.useyy);

                                                    const hetgData = [];
                                                    const aconData = [];
                                                    const baseData = [];
                                                    const totalData = [];

                                                    resultData.forEach((item) => {
                                                        // 난방
                                                        const hetg = item.elechetgqty + item.gashetgqty + item.hetghetgqty;
                                                        hetgData.push(hetg.toFixed(2));

                                                        // 냉방 사용량 계산
                                                        const acon = item.elecaconqty + item.gasaconqty + item.hetgaconqty;
                                                        aconData.push(acon.toFixed(2));

                                                        // 기저 사용량 계산
                                                        const base = item.elecbaseqty + item.gasbaseqty + item.hetgbaseqty;
                                                        baseData.push(base.toFixed(2));

                                                        // 총합 계산
                                                        const total = item.allallqty;
                                                        totalData.push(total.toFixed(2));
                                                    });

                                                    // 전기 시리즈 추가
                                                    chartOption.series.push({
                                                        name: '난방',
                                                        type: 'bar',
                                                        stack: 'advertising',
                                                        data: hetgData,
                                                        color: '#1679AB',
                                                    });

                                                    // 가스 시리즈 추가
                                                    chartOption.series.push({
                                                        name: '냉방',
                                                        type: 'bar',
                                                        stack: 'advertising',
                                                        data: aconData,
                                                        color: '#5DEBD7',
                                                    });

                                                    // 열 시리즈 추가
                                                    chartOption.series.push({
                                                        name: '기저',
                                                        type: 'bar',
                                                        stack: 'advertising',
                                                        data: baseData,
                                                        color: '#9DDE8B',
                                                    });

                                                    // 합계 시리즈 추가
                                                    chartOption.series.push({
                                                        name: '합계',
                                                        type: 'line',
                                                        data: totalData,
                                                        color: '#000000',
                                                    });

                                                chartInstance.setOption(chartOption);
                                                chartInstance.on('finished', () => {
                                                    console.log('에너지 소비량 : 년별-용도별 에너지 소비량 변화 차트 생성 완료');
                                                    // this.targetChartList[chartId] = 'created';
                                                    Vue.set(this.targetChartList, chartId, 'created');//이번 페이지는 Vue.set 함수를 써야만 watch가 반응하는 케이스임
                                                    this.uploadChartImage(chartInstance, chartId);
                                                });
                                        }).catch((error) => { console.error('오류내용:', error); });
            return chartInstance;
        },

        //년도별-용도별 백분위 변화 차트 생성
        makePercentileTrendByEnergyUsagePurposeOverYearsChartInstance(chartId) {
            const chartDomObj = document.getElementById(chartId);
            const chartInstance = echarts.init(chartDomObj);
            const chartOption = {
                                grid: {
                                    left: '30px',
                                    right: '30px', // 필요 시 우측 마진도 조정
                                    top: '50px', // 타이틀과 x축의 간격 조정
                                    bottom: '50px', // 범례와 x축의 간격 조정
                                },
                                legend: {
                                    bottom: 15, // 하단에 10px 위치
                                    itemWidth: 20, 
                                    itemHeight: 14,
                                    padding: 0, // 레전드와 차트 간의 마진을 최소화
                                },
                                title: {
                                    text: '연도별-용도별 백분위 변화',
                                    left: 'center',
                                    top: '10px', // 타이틀을 위로 이동하여 그래프와의 간격 줄이기
                                    textStyle: { fontSize: 16 },
                                },
                                xAxis: [
                                        {
                                            type: 'category',
                                            data: [],
                                        },
                                ],
                                yAxis: [
                                    {
                                        type: 'value',
                                        splitLine: { show: true },
                                    },
                                ],
                                series: [], //데이터는 api조회 해서 채운다.
        };

        this.$store.dispatch('main/FETCH_YEAR_ENGY_DATA', //년도별-용도별 백분위 변화
                                {
                                    mgmBldPk: this.reqParams.mgmBldPk,
                                }).then((resultData) => {
                                                    // xAxis.data를 업데이트
                                                    chartOption.xAxis[0].data = resultData.map((item) => item.useyy);

                                                    const hetgData = [];
                                                    const aconData = [];
                                                    const baseData = [];
                                                    const totalData = [];

                                                    resultData.forEach((item) => {
                                                        // 난방
                                                        //const hetg = item.elec_hetg_qty + item.gas_hetg_qty + item.hetg_hetg_qty;
                                                        const hetg = item.allhetgqtyperc;
                                                        hetgData.push(hetg);

                                                        // 냉방 사용량 계산
                                                        const acon = item.allaconqtyperc;
                                                        aconData.push(acon);

                                                        // 기저 사용량 계산
                                                        const base = item.allbaseqtyperc;
                                                        baseData.push(base);

                                                        // 총합 계산
                                                        const total = item.allallqtyperc;
                                                        totalData.push(total);
                                                    });

                                                    // 전기 시리즈 추가
                                                    chartOption.series.push({
                                                        name: '난방',
                                                        type: 'line',
                                                        data: hetgData,
                                                        color: '#E64A45',
                                                    });

                                                    // 가스 시리즈 추가
                                                    chartOption.series.push({
                                                        name: '냉방',
                                                        type: 'line',
                                                        data: aconData,
                                                        color: '#E6772E',
                                                    });

                                                    // 열 시리즈 추가
                                                    chartOption.series.push({
                                                        name: '기저',
                                                        type: 'line',
                                                        data: baseData,
                                                        color: '#F2C249',
                                                    });

                                                    // 합계 시리즈 추가
                                                    chartOption.series.push({
                                                        name: '합계',
                                                        type: 'line',
                                                        data: totalData,
                                                        color: '#4DB3B3',
                                                    });

                                                chartInstance.setOption(chartOption);
                                                chartInstance.on('finished', () => {
                                                    console.log('에너지 소비량 : 년별-용도별 백분위 변화 차트 생성 완료');
                                                    // this.targetChartList[chartId] = 'created';
                                                    Vue.set(this.targetChartList, chartId, 'created');//이번 페이지는 Vue.set 함수를 써야만 watch가 반응하는 케이스임
                                                    this.uploadChartImage(chartInstance, chartId);
                                                });
                                        }).catch((error) => { console.error('오류내용:', error); });
            return chartInstance;
        },

        // 월별-에너지원별 에너지 소비량 차트 생성
        makeEnergyUsageAmountByEnergySourceOverMonthChartInstance(chartId) {
            const chartDomObj = document.getElementById(chartId);
            const chartInstance = echarts.init(chartDomObj);
            const chartOption = {
                                grid: {
                                    left: '30px',
                                    right: '30px', // 필요 시 우측 마진도 조정
                                    top: '50px', // 타이틀과 x축의 간격 조정
                                    bottom: '50px', // 범례와 x축의 간격 조정
                                },
                                legend: {
                                    bottom: 15, // 하단에 10px 위치
                                    itemWidth: 20, 
                                    itemHeight: 14,
                                    padding: 0, // 레전드와 차트 간의 마진을 최소화
                                },
                                title: {
                                    text: '에너지원별 에너지 사용량',
                                    left: 'center',
                                    top: '10px', // 타이틀을 위로 이동하여 그래프와의 간격 줄이기
                                    textStyle: { fontSize: 16 },
                                },
                                xAxis: [
                                        {
                                            type: "category",
                                            data: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                                            axisPointer: { type: "shadow" },
                                            triggerEvent: true,
                                        },
                                ],
                                yAxis: [
                                        { //y축 왼쪽(단위)
                                            type: "value",
                                           // name: "kWh/m²",
                                            axisLine: { show: true },
                                            axisTick: { show: true },
                                            splitLine: {
                                                            show: true,
                                                            lineStyle: { type: "dashed" },
                                                        },
                                        },
                                        { //y축 오른쪽
                                        type: "value",
                                        name: "", // 오른쪽 단위값은 값이 있을때만 노출하기 위해 공백
                                        splitLine: {
                                                        show: true,
                                                        lineStyle: { type: "dashed" },
                                                    },
                                        },
                                ],
                                series: [], //데이터는 api조회 해서 채운다.
        };

        this.$store.dispatch("main/FETCH_ENERGY_KIND_CHART_DATA", // 월별-에너지원별 에너지 소비량
                                {
                                    mgmBldPk: this.reqParams.mgmBldPk,
                                    dataCode: 'engyKind', //에너지원별
                                    useYyCd: this.reqParams.year,
                                }).then(() => {
                                            const chartData = this.$store.state.main.energyChartTotal;
                                            chartData.forEach((v) => {
                                                                            const temp = {};

                                                                            temp.name = v.cd_nm;
                                                                            if (v.cd_nm === "수도") {
                                                                                temp.type = 'line';
                                                                                temp.yAxisIndex = 1;
                                                                                chartOption.yAxis[1].name = '수도\nL/m²';
                                                                            } else {
                                                                                temp.type = 'bar';
                                                                                temp.stack = 'advertising';
                                                                            }
                                                                            temp.itemStyle = { color: v.cd_color };

                                                                            let tempArr = [];
                                                                            tempArr = v.use_qty.split(',');

                                                                            for (const a in temp) {
                                                                                tempArr[a] = parseInt(tempArr[a], 10);
                                                                            }
                                                                            temp.data = tempArr;
                                                                            chartOption.series.push(temp);
                                                                        });
                                                chartInstance.setOption(chartOption);
                                                chartInstance.on('finished', () => {
                                                    console.log('에너지 소비량 : 월별-에너지원별 에너지 소비량 차트 생성 완료');
                                                    // this.targetChartList[chartId] = 'created';
                                                    Vue.set(this.targetChartList, chartId, 'created');//이번 페이지는 Vue.set 함수를 써야만 watch가 반응하는 케이스임
                                                    this.uploadChartImage(chartInstance, chartId);
                                                });
                                        }).catch((error) => { console.error('오류내용:', error); });
            return chartInstance;
        },

        // 월별-용도별 에너지 소비량 차트 생성
        makeEnergyConsumptionByEnergyUsagePurposeOverMonthChartInstance(chartId) {
            const chartDomObj = document.getElementById(chartId);
            const chartInstance = echarts.init(chartDomObj);
            const chartOption = {
                                grid: {
                                    left: '30px',
                                    right: '30px', // 필요 시 우측 마진도 조정
                                    top: '50px', // 타이틀과 x축의 간격 조정
                                    bottom: '50px', // 범례와 x축의 간격 조정
                                },
                                legend: {
                                    bottom: 15, // 하단에 10px 위치
                                    itemWidth: 20, 
                                    itemHeight: 14,
                                    padding: 0, // 레전드와 차트 간의 마진을 최소화
                                },
                                title: {
                                    text: '용도별 에너지 소비량',
                                    left: 'center',
                                    top: '10px', // 타이틀을 위로 이동하여 그래프와의 간격 줄이기
                                    textStyle: { fontSize: 16 },
                                },
                                xAxis: [
                                        {
                                            type: 'category',
                                            boundaryGap: false,
                                            data: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                                        },
                                ],
                                yAxis: [
                                        {
                                            type: 'value',
                                            splitLine: { show: true },
                                        },
                                ],
                                series: [], //데이터는 api조회 해서 채운다.
        };

        this.$store.dispatch("main/FETCH_ENERGY_KIND_CHART_DATA", // 월별-용도별 에너지 소비량
                                {
                                    mgmBldPk: this.reqParams.mgmBldPk,
                                    dataCode: 'clsfKind', //용도별
                                    useYyCd: this.reqParams.year,
                                }).then(() => {
                                            const chartData = this.$store.state.main.energyChartTotal;
                                            chartData.forEach((v) => {
                                                                            const temp = {};

                                                                            temp.name = v.cd_nm;
                                                                            temp.type = 'line';
                                                                            temp.stack = 'Total';
                                                                            temp.showSymbol = true;
                                                                            temp.color = v.cd_color;
                                                                            temp.areaStyle = { color: v.cd_color };
                                                                            temp.lineStyle = { width: 1 };
                                                                            let tempArr = [];
                                                                            tempArr = v.use_qty.split(',');
                                                                            for (const a in temp) {
                                                                                tempArr[a] = parseInt(tempArr[a], 10);
                                                                            }
                                                                            temp.data = tempArr;
                                                                            chartOption.series.push(temp);
                                                                        });
                                                chartInstance.setOption(chartOption);
                                                chartInstance.on('finished', () => {
                                                    console.log('에너지 소비량 : 월별-용도별 에너지 소비량 차트 생성 완료');
                                                    // this.targetChartList[chartId] = 'created';
                                                    Vue.set(this.targetChartList, chartId, 'created');//이번 페이지는 Vue.set 함수를 써야만 watch가 반응하는 케이스임
                                                    this.uploadChartImage(chartInstance, chartId);
                                                });
                                        }).catch((error) => { console.error('오류내용:', error); });
            return chartInstance;
        },

        // 단위 면적별 에너지 사용량 차트 생성
        makeEnergyConsumptionPerUnitAreaChartInstance(chartId) {
            const chartDomObj = document.getElementById(chartId);
            const chartInstance = echarts.init(chartDomObj);
            const confirmEnergyComparisonExclusion = () => { // 유사건물비교 단위면적 챠트 생성 불가 메시지 팝업 처리 함수
                const userConfirmResult = confirm('비교를 위한 데이터가 충분하지 않아 에너지소비량 유사건물군 비교가 불가합니다.\n제외하고 받으시겠습니까?');
                if (userConfirmResult) { // 유사건물군비교 기능 선택 해제하고 나머지 리포트는 그대로 출력 진행한다.
                    console.log('비교대상 건물군 정보 부족으로 유사건물군비교 기능 선택 해제하고 나머지 리포트는 그대로 출력 진행한다.');
                    this.reqParams.smlrBuldEngyUseCompare = 'N';
                    Vue.delete(this.targetChartList, 'energyConsumptionPerUnitAreaChart');

                } else {
                    console.log('리포트 생성 작업 취소 및 창 닫기');
                    window.close();
                }
            };
            //1.유사건물군을 찾기 위한 건물유형 찾기
            this.$store.dispatch('main/FETCH_SELECTED_SIMILAR_BUILDING_DATA',
                                {
                                    mgmBldPk: this.reqParams.mgmBldPk,
                                    useYy: this.reqParams.year,
                                    }).then((rtnData) => {
                                                        if (rtnData.resultFlag) { //유사건물군 유형 데이터가 있다면 차트 생성진행하자
                                                            const curBuldGrpInf = rtnData.resultData; //건물군유형정보 (group of building)
                                                            const curBuldEngyUseQty = curBuldGrpInf.use_qty; // 현재건물의 에너지 사용량
                                                            const smlrBuldDataReqParam = { insltStCd: curBuldGrpInf.inslt_st_cd, //비교건물군의 사용승인일
                                                                                             mainPurpsCd: curBuldGrpInf.main_purps_cd, //비교건물군의 건물용도
                                                                                             sidoId: curBuldGrpInf.si_do_id, //비교건물군의 광역시도
                                                                                             sigunguId: '0', //비교건물군의 시군구  는 무조건 전체:0로 설정하기로 함
                                                                                             useYyCd: this.reqParams.year, //비교년도
                                                                                        }; //참고수치: 중위값으로만 비교하기로 한다. perc_50
                                                            //2. 유사건물군 데이터 목록 조회 하기
                                                            this.$store.dispatch('main/FETCH_SIMILAR_BUILDING_DATA', smlrBuldDataReqParam)
                                                                        .then((smlrBuldDataList) => {
                                                                                                if (smlrBuldDataList == null || smlrBuldDataList.length === 0) {
                                                                                                    confirmEnergyComparisonExclusion(); //유사건물비교 단위면적 챠트 생성 불가 메시지 팝업
                                                                                                }

                                                                                                //유사건물군 비교 결과를 묶어서 서버로 보내는게 좋겠다(재쿼리방지.로직분할방지)
                                                                                                const smlrBuldEngyCmprResult = {
                                                                                                    cmprSidoNm: curBuldGrpInf.sido_nm, //광역시도명
                                                                                                    cmprSiGunGuNm: '전체', //시군구명 (비교건물군의 시군구  는 무조건 전체:0로 설정하기로 함)
                                                                                                    cmprReferenceTypeNm: '중위값', //중위값 비교만 사용함
                                                                                                    cmprBuldMainPurpsNm: curBuldGrpInf.main_purps_cd_nm, //비교건물군 건물용도
                                                                                                    cmprBuldInsltStNm: curBuldGrpInf.inslt_st_cd_nm, //비교건물군 사용승인일(단열기준일)
                                                                                                    cmprYear: this.reqParams.year, //비교건물군 에너지 사용 통계년도
                                                                                                    smlrBuldEngyUseQty: smlrBuldDataList[0].perc_50.toLocaleString(), //유사시설 군 에너지사용량 (중위값)
                                                                                                    curBuldEngyUseQty: curBuldEngyUseQty.toLocaleString(), //현재(사용자)건물 에너지사용량
                                                                                                    cmprEngyUseQty: (curBuldEngyUseQty - smlrBuldDataList[0].perc_50).toLocaleString(), //비교결과 (양수면 빨간색으로 음수면 파란색으로 표시해야함함)
                                                                                                };
                                                                                                this.reqParams['smlrBuldEngyCmprInfo'] = smlrBuldEngyCmprResult;

                                                                                                //차트 생성을 위한 로직
                                                                                                const calcMarkerPosition = (data, referVal) => {
                                                                                                    for (let i = 0; i < data.length; i += 1) {
                                                                                                        if (data[i].use_qty > referVal) {
                                                                                                            return i - 1;
                                                                                                        }
                                                                                                    }
                                                                                                    return data.length - 1;
                                                                                                };
                                                                                                const curBuldMarkText = curBuldEngyUseQty !== null ? `{a|사용자 건물\n${curBuldEngyUseQty.toLocaleString()}\nkWh/㎡}` : `{a|사용자 건물\n-\nkWh/㎡}`;
                                                                                                const smlrBuldMarkText = smlrBuldDataList[0].perc_50 != null ? `{a|중위값\n${smlrBuldDataList[0].perc_50.toLocaleString()}\nkWh/㎡}` : `{a|중위값\n-\nkWh/㎡}`;
                                                                                                const curBuldMarkPoint = calcMarkerPosition(smlrBuldDataList, curBuldEngyUseQty);
                                                                                                const smlrBuldMarkPoint = calcMarkerPosition(smlrBuldDataList, smlrBuldDataList[0].perc_50);
                                                                                                const chartOption = {
                                                                                                                    grid: {
                                                                                                                        left: '40px',
                                                                                                                        right: '40px', // 필요 시 우측 마진도 조정
                                                                                                                        top: '50px', // 타이틀과 x축의 간격 조정
                                                                                                                        bottom: '50px', // 범례와 x축의 간격 조정
                                                                                                                    },
                                                                                                                    legend: {
                                                                                                                        bottom: 15, // 하단에 10px 위치
                                                                                                                        itemWidth: 20, 
                                                                                                                        itemHeight: 14,
                                                                                                                        padding: 0, // 레전드와 차트 간의 마진을 최소화
                                                                                                                    },
                                                                                                                    title: {
                                                                                                                        text: '단위면적 E사용량(kWh/㎡)',
                                                                                                                        left: 'center',
                                                                                                                        top: '10px', // 타이틀을 위로 이동하여 그래프와의 간격 줄이기
                                                                                                                        textStyle: { fontSize: 16 },
                                                                                                                    },
                                                                                                                    xAxis: [{
                                                                                                                            type: "category",
                                                                                                                            name: "(kWh/㎡)",
                                                                                                                            data: smlrBuldDataList.map((v) => v.use_qty.toLocaleString()),
                                                                                                                            nameTextStyle: { fontSize: 10 },
                                                                                                                            axisPointer: { type: "shadow" },
                                                                                                                            triggerEvent: true,
                                                                                                                            nameGap: 20,
                                                                                                                            nameLocation: "middle", // 이름 위치를 중앙으로 이동
                                                                                                                            
                                                                                                                    }],
                                                                                                                    yAxis: [{ 
                                                                                                                            type: "value", 
                                                                                                                            // name: "(사용량)",
                                                                                                                            // nameTextStyle: { fontSize: 10 },
                                                                                                                            // nameGap: 20,
                                                                                                                            // nameLocation: "start", // 이름 위치를 하단으로 이동
                                                                                                                            // nameRotate: 90, // 텍스트를 회전하여 하단에 가깝게 표시
                                                                                                                    }],
                                                                                                                    series: [{
                                                                                                                                type: 'bar',
                                                                                                                                data: smlrBuldDataList.map((v) => v.cnt),
                                                                                                                                markPoint: {
                                                                                                                                            data: [
                                                                                                                                                    {
                                                                                                                                                        type: 'custom',
                                                                                                                                                        name: '중위값 표식1',
                                                                                                                                                        xAxis: smlrBuldMarkPoint,
                                                                                                                                                        yAxis: 1,
                                                                                                                                                        symbolSize: 140,
                                                                                                                                                        label: {
                                                                                                                                                                show: true,
                                                                                                                                                                formatter () { return smlrBuldMarkText; },
                                                                                                                                                                rich: {
                                                                                                                                                                    a: {
                                                                                                                                                                        fontSize: 12,
                                                                                                                                                                        color: 'black',
                                                                                                                                                                        fontWeight: 'bold',
                                                                                                                                                                        align: 'center',
                                                                                                                                                                    },
                                                                                                                                                                },
                                                                                                                                                        },
                                                                                                                                                        itemStyle: {
                                                                                                                                                            color: 'rgba(241,246,250,0.5)',
                                                                                                                                                            borderColor: 'rgb(153, 153, 51)',
                                                                                                                                                        },
                                                                                                                                                    },
                                                                                                                                                    {
                                                                                                                                                        type: 'custom',
                                                                                                                                                        name: '사용자 건물',
                                                                                                                                                        xAxis: curBuldMarkPoint,
                                                                                                                                                        yAxis: 0,
                                                                                                                                                        symbolSize: 140,
                                                                                                                                                        label: {
                                                                                                                                                                show: true,
                                                                                                                                                                formatter () { return curBuldMarkText; },
                                                                                                                                                                rich: {
                                                                                                                                                                        a: {
                                                                                                                                                                            fontSize: 12,
                                                                                                                                                                            color: 'black',
                                                                                                                                                                            fontWeight: 'bold',
                                                                                                                                                                            align: 'center',
                                                                                                                                                                        },
                                                                                                                                                                    },
                                                                                                                                                        },
                                                                                                                                                        itemStyle: {
                                                                                                                                                            color: 'rgba(255,255,250,0.5)',
                                                                                                                                                            borderColor: 'rgb(0, 0, 0)',
                                                                                                                                                        },
                                                                                                                                                    },
                                                                                                                                                ],
                                                                                                                                            },
                                                                                                                    }],
                                                                                                };
                                                                                                chartInstance.setOption(chartOption);
                                                                                                chartInstance.on('finished', () => {
                                                                                                    console.log('유사건물군비교 : 단위 면적별 에너지 사용량 차트 생성 완료');
                                                                                                    // this.targetChartList[chartId] = 'created';
                                                                                                    Vue.set(this.targetChartList, chartId, 'created');//이번 페이지는 Vue.set 함수를 써야만 watch가 반응하는 케이스임
                                                                                                    this.uploadChartImage(chartInstance, chartId);
                                                                                                });
                                                                        }).catch((error) => { console.error('오류내용:', error); });
                                                        } else {
                                                            confirmEnergyComparisonExclusion(); //유사건물비교 단위면적 챠트 생성 불가 메시지 팝업
                                                        }
                                                    }).catch((error) => { console.error('오류내용:', error); });
        },

        // 서버에 이미지 업로드
        uploadChartImage(chartInstance, chartId) {
            // setTimeout( () => {

                if (chartInstance) {
                    const imgData = chartInstance.getDataURL({
                        type: 'jpeg',
                        backgroundColor: '#fff',
                    });

                    // 이미지 데이터를 Blob으로 변환
                    const byteString = atob(imgData.split(',')[1]);
                    const ab = new ArrayBuffer(byteString.length);
                    const ia = new Uint8Array(ab);
                    for (let i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                    }
                    const blob = new Blob([ab], { type: 'image/jpeg' });
                    const chartFileName = `${Date.now()}_${chartId}.jpg`;
                    const formData = new FormData();// FormData에 Blob 파일 추가
                    formData.append('file', blob, 'chart.jpg');
                    formData.append('chartFileName', chartFileName); //  추가

                    // 서버로 업로드 요청
                    axios.post('/api/pdfExport/uploadEChartGraphImage',
                                formData,
                                { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
                                                console.log("업로드 성공:", response.data);
                                                // this.targetChartList[chartId] = 'uploaded';
                                                Vue.set(this.targetChartList, chartId, 'uploaded'); //이번 페이지는 Vue.set 함수를 써야만 watch가 반응하는 케이스임
                                                this.reqParams[chartId] = response.data.resultData; //서버에 업로드된 파일명을 세팅해서 보내야함함
                                                console.log(this.targetChartList);
                                                console.log(this.reqParams);
                                                }).catch(error => { console.error("업로드 실패:", error); });
                }
            // },
            // 5000);
        },


        async requestPdfCreate() {
            // console.log(this.reqParams);

            try {
                const response = await axios.post('/api/pdfExport/downloadBuildingEnergyReport',
                this.reqParams, // 필요한 요청 데이터를 여기에 전달
                {
                    responseType: 'blob', // PDF 데이터를 Blob 형식으로 받아옴
                });

                // 응답으로 받은 데이터를 Blob 객체로 변환
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
            
                // {   //새창으로 여는 방식
                //     window.open(url, '_self');
                //     // window.open(url, '_blank');
                // }

                {//다운로드방식
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'BuildingEnergyReport.pdf';
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }

                // URL 객체와 링크 정리
                window.URL.revokeObjectURL(url);

                
            } catch (error) {
                console.error("PDF 생성 요청 실패:", error);
            }
        },
        
        // async bepaPdfReportDownload(mgmBldPk){ ;
        //     try {
        //             const bepaPdfDownloadUrl =  "/api/main/pdf/" + mgmBldPk + ".pdf"
        //             console.log(bepaPdfDownloadUrl);
        //             // PDF 파일 다운로드 요청
        //             const response = await axios.get(bepaPdfDownloadUrl, {
        //                 responseType: "blob", // 응답 데이터를 Blob 형태로 설정
        //             });

        //             // Blob 데이터 생성 및 파일 다운로드 링크 생성
        //             const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        //             const downloadUrl = window.URL.createObjectURL(pdfBlob);

        //             // {//새창으로여는방식
        //             //     window.open(downloadUrl, '_blank');
        //             // }

        //             { ///다운로드방식
        //                 const link = document.createElement("a");
        //                 link.href = downloadUrl;
        //                 link.download = "bepaReport.pdf"; // 저장될 파일명 설정
        //                 document.body.appendChild(link);
        //                 link.click();
        //                 link.remove();
        //             }

        //             // Blob URL을 해제하여 메모리 해제
        //             window.URL.revokeObjectURL(downloadUrl);
        //         } catch (error) {
        //             console.error("BEPA 리포트파일 다운로드 실패:", error);
        //         }
        // }

    },
};
</script>

<style >
.chartContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    /* background-color: rgba(0,0,0,0.2); */
    flex-wrap: wrap; /* 줄바꿈 허용 */

}
.reportMakeLoading {
    background-image: url('/buildEnergyReportMakeLoading.gif');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 300px;
    height: 300px;
}
.chart{
    display: none; 
}
footer {
    display: none;
}
</style>
